import React from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Button, styled } from "@mui/material";
import { Link } from "react-router-dom";

const StyledButton = styled(Button)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "black",
  fontSize: "12px",
  "&:hover": {
    color: "#075598",
    transition: "all",
  },
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  backgroundColor: "white",
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: "white",
  boxShadow: "none",
}));

const urlRoutes = [
  {
    path: "/",
    name: "INICIO",
  },
  {
    path: "/productos",
    name: "PRODUCTOS",
  },
  {
    path: "/productos#plato-de-ducha",
    name: "PLATO DE DUCHA",
  },
  {
    path: "/contacto",
    name: "CONTACTO",
  },
];

const BurgerMenu = () => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(!open);
  };

  const list = () => (
    <div role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
      <List>
        {urlRoutes.map((item, i) => (
          <ListItem button key={i}>
            <StyledButton
              className="navbar-links"
              component={Link}
              to={item.path}
            >
              {item.name}
            </StyledButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <StyledToolbar variant="dense">
        <StyledIconButton aria-label="menu" onClick={toggleDrawer}>
          <MenuIcon />
        </StyledIconButton>
      </StyledToolbar>

      <Drawer anchor="left" open={open} onClose={toggleDrawer}>
        {list()}
      </Drawer>
    </div>
  );
};

export default BurgerMenu;
