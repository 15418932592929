import React, { useEffect } from "react";
import { Button, Typography } from "@mui/material";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import WhatsAppButton from "../components/WhatsAppButton";
import TableAgeData from "../components/TableAgeData";
import "./Home.css";
import ImageProductOverlay from "../components/ImageProductOverlay";
import { Link } from "react-router-dom";

const imageArray = [
  {
    img: "/nuestros-productos/producto1.jpg",
    title: "Plato de ducha",
    route: "#plato-de-ducha",
    rows: 1,
    cols: 1,
  },
  {
    img: "/nuestros-productos/producto2.jpg",
    title: "Mampara de ducha",
    route: "#mampara-de-ducha",
    rows: 1,
    cols: 1,
  },
  {
    img: "/nuestros-productos/producto3.jpg",
    title: "Griferia",
    route: "#griferia",
    rows: 1,
    cols: 1,
  },
  {
    img: "/nuestros-productos/producto4.jpg",
    title: "Inodoros",
    route: "#inodoros",
    rows: 1,
    cols: 1,
  },
  {
    img: "/nuestros-productos/producto5.jpg",
    title: ["Barra de apoyo o asideros"],
    route: "#barra-de-apoyo",
    rows: 1,
    cols: 1,
  },
  {
    img: "/nuestros-productos/producto6.jpg",
    title: "Puerta de acceso al baños",
    route: "#puerta-de-acceso",
    rows: 1,
    cols: 1,
  },
  {
    img: "/nuestros-productos/producto7.jpg",
    title: "Suelos antideslizantes",
    route: "#suelos-anti",
    rows: 1,
    cols: 1,
  },
  {
    img: "/nuestros-productos/producto8.jpg",
    title: "Lavado suspendido",
    route: "#lavado-suspendido",
    rows: 1,
    cols: 1,
  },
  {
    img: "/nuestros-productos/producto9.jpg",
    title: "Espejo",
    route: "#espejo",
    rows: 1,
    cols: 1,
  },
  {
    img: "/nuestros-productos/producto10.jpg",
    title: "Llamador de emergencia",
    route: "#llamador-emergencia",
    rows: 1,
    cols: 1,
  },
  {
    img: "/nuestros-productos/producto11.jpg",
    title: "Asientos",
    route: "#asientos",
    rows: 1,
    cols: 1,
  },
];

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <div className="banner-container">
        <div className="banner-content-container">
          <Typography className="banner-title">
            Baños para la tercera edad y movilidad reducida
          </Typography>
          <Typography className="banner-subtitle">
            SEGURIDAD | TRANQUILIDAD | CONFIANZA
          </Typography>
          <Typography className="banner-subtitle">
            Transforma tus baños en espacios seguros y accesibles para la
            tercera edad. Descubre nuestra amplia selección de productos
            especializados y platos de ducha diseñados pensando en su bienestar.
            ¡Bienvenido a un nuevo nivel de confort y tranquilidad!
          </Typography>
        </div>
      </div>
      <div className="our-products-section">
        <Typography className="our-products-title">
          Conoce nuestros productos
        </Typography>
        <div>
          <div className="grid-container">
            {imageArray.map((item, index) => (
              <div key={index} className="our-products-img-container">
                <ImageProductOverlay
                  imageSrc={item.img}
                  name={item.title}
                  urlRoute={item.route}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="know-more-banner-container">
        <div className="know-more-content">
          <div className="duo-title-container">
            <Typography className="basic-color-text">
              Facilitando la Vida Diaria:
            </Typography>
            <Typography className="basic-text">
              Accesibilidad en el Espacio del Baño
            </Typography>
          </div>
          <Typography className="banner-subtitle">
            Para ello disponemos de un gran equipamiento que permitirá hacer más
            sencillas las necesidades más elementales. En cuanto a los elementos
            del cuarto de baño es recomendable para mejor la accesibilidad en su
            interior que la zona de la ducha, inodoro y lavabo se encuentren
            alineados en la misma pared
          </Typography>
        </div>
        <div>
          <Button
            variant="contained"
            className="color-button"
            component={Link}
            to="/productos"
          >
            Conoce más
          </Button>
        </div>
      </div>
      <div className="info-section-container">
        <div className="align-end">
          <div className="info-content">
            <Typography className="h2">DUCHASECURE</Typography>
            <div className="info-title">
              <Typography className="info-title">
                Accesibilidad y Seguridad
              </Typography>
            </div>
            <br />
            <Typography className="info-subtitle">
              Según el Instituto Nacional de Estadística, España es el segundo
              país del mundo con mejor esperanza de vida y, por ende, cada vez
              hay más gente mayor que requiere soluciones de accesibilidad para
              facilitar su autonomía. Se necesitan espacios más amplios para
              poder maniobrar, tanto para las personas mayores como para
              aquellas que sufren algún tipo de discapacidad o cuya autonomía se
              ha visto reducida por alguna enfermedad. En España, hay más de
              4,38 millones de hombres y mujeres residentes en hogares españoles
              (94,9 de cada mil habitantes) con algún tipo de discapacidad o
              limitación. Así lo revelan las cifras del INE, en la encuesta
              ‘Discapacidad, Autonomía Personal y Situaciones de Dependencia’,
              con datos correspondientes a 2020 y publicada en 2022. Los
              problemas de movilidad son el tipo de discapacidad más frecuente
              entre las personas de 6 años y más, con una tasa de 54 mil
              habitantes. El 55,7 % de las personas con discapacidad presenta
              dificultades importantes de movilidad, el 46,5 % tiene problemas
              relacionados con la vida doméstica y el 31,6 % con el cuidado
              personal. Por otro lado, el número de personas con discapacidad
              aumenta a partir de los 45 años.
            </Typography>
          </div>
          <div className="info-img-container">
            <img
              src="/image-3.jpg"
              alt="plato de ducha"
              loading="lazy"
              className="info-img"
            />
          </div>
        </div>
      </div>
      <div className="table-section-container">
        <TableAgeData />
        <div className="margin-bottom-top">
          <Typography className="info-subtitle">
            Una misma persona puede tener más de un tipo de discapacidad.
          </Typography>
          <br />
          <Typography className="info-subtitle">
            Ya sabemos por tramos de edad el numero de personas que padecen
            algún tipo de discapacidad o limitación y que requiere soluciones de
            accesibilidad en el hogar. Pero también nos tenemos que hacer la
            siguiente pregunta ¿Cuántas personas mayores residen en España?
          </Typography>
          <Typography className="info-subtitle">
            Como se ha comentado al principio, España es uno de los países con
            mayor esperanza de vida, así lo cuantifico el INE con estas cifras
            de población por tramos de edad
          </Typography>
          <br />
          <div>
            <Typography className="info-subtitle">
              <span style={{ fontWeight: "bold" }}>• De 21 a 30 años: </span>
              983.589 personas.
            </Typography>
            <Typography className="info-subtitle">
              <span style={{ fontWeight: "bold" }}>• De 31 a 40 años: </span>
              956.048 personas.
            </Typography>
            <Typography className="info-subtitle">
              <span style={{ fontWeight: "bold" }}>• De 41 a 50 años: </span>
              803.365 personas.
            </Typography>
            <Typography className="info-subtitle">
              <span style={{ fontWeight: "bold" }}>• De 51 a 60 años: </span>
              106.614 personas.
            </Typography>
            <Typography className="info-subtitle">
              <span style={{ fontWeight: "bold" }}>• De 61 a 70 años: </span>
              462.969 personas.
            </Typography>
            <Typography className="info-subtitle">
              <span style={{ fontWeight: "bold" }}>• De 71 a 80 años: </span>
              907.804 personas.
            </Typography>
            <Typography className="info-subtitle">
              <span style={{ fontWeight: "bold" }}>• De 81 a 90 años: </span>
              138.812 personas.
            </Typography>
            <Typography className="info-subtitle">
              <span style={{ fontWeight: "bold" }}>• Más de 91 años: </span>
              426 personas.
            </Typography>
          </div>
          <br />
          <Typography className="info-subtitle">
            Con estas cifras podemos observar que el mayor numero de personas se
            encuentran entre los 41 y los 50 años, seguido por el tramo de edad
            de 51 a 60 años
          </Typography>
          <br />
          <Typography className="info-subtitle">
            DuchaSecure ha tenido en cuenta los problemas de movilidad,
            diseñando cuartos de baño (de acuerdo a la legislación vigente)
            elegantes y funcionales, adaptados a cada necesidad, valorando con
            detalle sus capacidades y limitaciones.
          </Typography>
          <Typography className="info-subtitle">
            Con una construcción y materiales que suponen una vía importante
            para ayudar a mejorar la calidad de vida de estas personas, se han
            creado espacios más amplios para poder maniobrar con facilidad. Los
            elementos incluyen asideros, barras de protección, asas de seguridad
            para inodoros, pavimentos antideslizantes tanto en seco como en
            mojado, bandas antideslizantes en platos de ducha, y cualquier otro
            tipo de elemento que facilite la higiene diaria.
          </Typography>
          <br />
          <Typography className="info-subtitle">
            No se deja de lado un sistema de llamada de auxilio (timbres
            inalámbricos) que puedan accionarse desde dentro, instalado de
            manera que pueda ser utilizado con facilidad, y que la entrada
            disponga de un mecanismo que permita su apertura desde el exterior
            en caso de emergencia, así como dispositivos de detección de caídas
            e inundaciones, y sistemas de ajuste de la altura del lavabo.
          </Typography>
          <Typography className="info-subtitle">
            Para DuchaSecure, lo más fundamental que hay dentro del hogar son
            las ayudas técnicas para el baño, ya que gracias a ellas se
            facilitan los actos cotidianos y más básicos de toda persona, y se
            evitan posibles accidentes.
          </Typography>
          <br />
          <Typography className="info-subtitle">
            Para ello, disponemos de un gran equipamiento que permitirá hacer
            más sencillas las necesidades más elementales. En cuanto a los
            elementos del cuarto de baño, es recomendable, para mejorar la
            accesibilidad en su interior, que la zona de la ducha, el inodoro y
            el lavabo se encuentren alineados en la misma pared.
          </Typography>
          <Typography className="info-subtitle">
            Algunos elementos destacados en cuanto a ayudas técnicas se refiere
            dentro del cuarto de baño van destinados al inodoro y a la ducha.
            Colocados en cualquier lugar donde se necesite un apoyo extra,
            minimizaremos con ello el riesgo de caídas y resbalones, y ayudarán
            a los usuarios a moverse por el baño. Se instalan normalmente en la
            ducha, la bañera y junto al lavabo, pero en la zona del inodoro
            deben colocarse asideros horizontales a ambos lados, situados a 75
            cm.
          </Typography>
        </div>
      </div>
      <WhatsAppButton />
      <div className="footer-section">
        <Footer />
      </div>
    </>
  );
};
export default Home;
