import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  CircularProgress,
  Typography,
} from "@mui/material";

const prodUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMailComplete, setIsMailComplete] = useState(false);
  const [isMailRejected, setIsMailRejected] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIsMailComplete(false);
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsMailComplete(false);
      setIsLoading(true);
      const response = await fetch(prodUrl + "/send-email.php", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-KEY": apiKey,
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      if (result) {
        setIsLoading(false);

        if (result.status === 200) {
          setFormData({
            name: "",
            email: "",
            phone: "",
            message: "",
          });
          setIsMailComplete(true);
        } else {
          setIsMailRejected(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Nombre"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label="Teléfono"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Mensaje"
          name="message"
          multiline
          rows={4}
          value={formData.message}
          onChange={handleChange}
          required
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px",
          }}
        >
          {isLoading === true ? <CircularProgress /> : null}
          {isMailComplete === true ? (
            <Typography style={{ color: "green", fontFamily: "Questrial" }}>
              Mensaje enviado correctamente.
            </Typography>
          ) : null}
          {isMailRejected === true ? (
            <Typography style={{ color: "red", fontFamily: "Questrial" }}>
              No se pudo enviar tu mensaje.
            </Typography>
          ) : null}
        </div>
        <Button variant="contained" color="primary" type="submit">
          Enviar
        </Button>
      </form>
    </Container>
  );
};

export default ContactForm;
