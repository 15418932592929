import React from "react";
import Home from "./view/Home";
import Products from "./view/Products";
import Contact from "./view/Contact";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ThemeProvider, createTheme } from "@mui/material";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/productos",
      element: <Products />,
    },
    {
      path: "/productos#plato-de-ducha",
      element: <Products />,
    },
    {
      path: "/contacto",
      element: <Contact />,
    },
  ]);
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>DuchaSecure</title>
        </Helmet>
        <RouterProvider router={router}></RouterProvider>
      </div>
    </ThemeProvider>
  );
};

export default App;
