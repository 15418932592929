import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledHeaderTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#075598",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(discapacidad, total, age1, age2, age3, age4) {
  return {
    name: discapacidad,
    total: total,
    age1,
    age2,
    age3,
    age4,
  };
}

const rows = [
  createData("Movilidad", 55.7, 38.2, 53.1, 55, 65.1),
  createData("Vida doméstica", 46.5, 38.1, 34.6, 40.8, 64.5),
  createData("Autocuidado", 31.6, 25.7, 20.2, 25.7, 48.5),
  createData("Audición", 28.5, 12.4, 21.2, 31, 38.5),
  createData("Visión", 24.3, 17.9, 24.7, 23.9, 26.9),
  createData("Comunicación", 21.9, 31.8, 13.4, 15, 31.4),
  createData("Aprendizaje", 16.3, 28.5, 10.2, 10.3, 22.1),
  createData("Interacciones sociales", 14.1, 32.5, 12.3, 6.8, 14.9),
];

export default function CustomizedTables() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledHeaderTableCell>DISCAPACIDAD</StyledHeaderTableCell>
            <StyledHeaderTableCell align="right">TOTAL</StyledHeaderTableCell>
            <StyledHeaderTableCell align="right">
              De 6 a 44
            </StyledHeaderTableCell>
            <StyledHeaderTableCell align="right">
              De 45 a 64
            </StyledHeaderTableCell>
            <StyledHeaderTableCell align="right">
              De 65 a 79
            </StyledHeaderTableCell>
            <StyledHeaderTableCell align="right">
              80 y más
            </StyledHeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.total}%</StyledTableCell>
              <StyledTableCell align="right">{row.age1}%</StyledTableCell>
              <StyledTableCell align="right">{row.age2}%</StyledTableCell>
              <StyledTableCell align="right">{row.age3}%</StyledTableCell>
              <StyledTableCell align="right">{row.age4}%</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
