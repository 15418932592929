import React from "react";
import { Typography, Button, styled } from "@mui/material";
import { Link } from "react-router-dom";
import "./Footer.css";

const StyledButton = styled(Button)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "white",
  fontSize: "12px",
  "&:hover": {
    color: "#84b3f8",
    transition: "all",
  },
}));

const Footer = () => {
  return (
    <>
      <div className="footer-title">
        <Typography>DUCHASECURE © Todos los derechos reservados</Typography>
        <div className="button-container">
          <StyledButton className="navbar-links" component={Link} to="/">
            INICIO
          </StyledButton>
          <StyledButton
            className="navbar-links"
            component={Link}
            to="/productos#plato-de-ducha"
          >
            PLATO DE DUCHA
          </StyledButton>
          <StyledButton
            className="navbar-links"
            component={Link}
            to="/productos"
          >
            PRODUCTOS
          </StyledButton>
          <StyledButton
            className="navbar-links"
            component={Link}
            to="/contacto"
          >
            CONTACTO
          </StyledButton>
        </div>
      </div>
    </>
  );
};

export default Footer;
