import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import "./ImageProductOverlay.css";
import { Link } from "react-router-dom";

const ImageContainer = styled(Box)(({ hover }) => ({
  position: "relative",
  width: "100%",
  height: "auto",
  overflow: "hidden",
  transform: hover ? "scale(1.05)" : "scale(1)",
  transition: "transform 0.3s ease",
}));

const Overlay = styled(Box)(({ hover }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "center",
  transition: "background-color 0.3s ease",
  backgroundColor: "rgba(7, 85, 152, 0.6)",
}));

const ImageProductOverlay = ({ imageSrc, name, urlRoute }) => {
  const [hover, setHover] = React.useState(false);

  return (
    <ImageContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      hover={hover}
    >
      <Button
        component={Link}
        to={`/productos${urlRoute}`}
        style={{ padding: "0px" }}
      >
        <img
          src={imageSrc}
          alt="Your description"
          style={{ width: "100%", height: "100%" }}
        />
        <Overlay hover={hover}>
          <div style={{ width: "100%", height: "100%" }}>
            <Typography variant="h6" className="Image-product-title">
              {name}
            </Typography>
          </div>
          <div
            style={{
              width: "100%",
              height: "60px",
              padding: "2%",
              marginBottom: "10px",
              marginLeft: "10px",
            }}
          >
            <Button
              className="See-more-button"
              component={Link}
              to={`/productos${urlRoute}`}
            >
              Ver más
            </Button>
          </div>
        </Overlay>
      </Button>
    </ImageContainer>
  );
};

export default ImageProductOverlay;
