import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import WhatsAppButton from "../components/WhatsAppButton";
import ContactForm from "../components/ContactForm";
import "./Contact.css";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <div className="form-section">
        <div className="form-container">
          <div className="contact-text-container">
            <div>
              <div className="">
                <div className="info-title">
                  <Typography className="info-title">Contacto</Typography>
                </div>
                <br />
                <br />
                <Typography className="info-subtitle">
                  ¡Ponte en contacto con nosotros!
                </Typography>
                <Typography className="info-subtitle">
                  Estamos aquí para responder a tus preguntas, brindarte
                  asesoramiento personalizado y ayudarte a encontrar las
                  soluciones ideales para tus baños adaptados a la tercera edad
                  y platos de ducha. Completa el formulario de contacto o
                  utiliza la información proporcionada para comunicarte
                  directamente con nuestro equipo experto. ¡Esperamos poder
                  ayudarte pronto.
                </Typography>
              </div>
            </div>
          </div>
          <ContactForm />
        </div>
      </div>
      <WhatsAppButton />
      <div className="footer-section">
        <Footer />
      </div>
    </>
  );
};
export default Contact;
