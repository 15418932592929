import React from "react";
import { AppBar, Button, Box, styled } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import BurgerMenu from "./BurgerMenu";
import "./Navbar.css";

const StyledButton = styled(Button)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "black",
  fontSize: "12px",
  "&:hover": {
    color: "#075598",
    transition: "all",
  },
}));

const Navbar = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <>
      <AppBar className="navbar-title">
        <div className="button-nav-container">
          <StyledButton component={Link} to="/">
            <img
              src="/logotipo.png"
              alt="plato de ducha"
              loading="lazy"
              className="logo-img"
            />
          </StyledButton>
          {isMobile ? (
            <BurgerMenu />
          ) : (
            <Box className="link-container">
              <StyledButton className="navbar-links" component={Link} to="/">
                INICIO
              </StyledButton>
              <StyledButton
                className="navbar-links"
                component={Link}
                to="/productos#plato-de-ducha"
              >
                PLATO DE DUCHA
              </StyledButton>
              <StyledButton
                className="navbar-links"
                component={Link}
                to="/productos"
              >
                PRODUCTOS
              </StyledButton>
              <StyledButton
                className="navbar-links"
                component={Link}
                to="/contacto"
              >
                CONTACTO
              </StyledButton>
            </Box>
          )}
        </div>
      </AppBar>
    </>
  );
};

export default Navbar;
