import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import WhatsAppButton from "../components/WhatsAppButton";
import ProductList from "../components/ProductList";
import { Typography } from "@mui/material";
import "./Products.css";
import Footer from "../components/Footer";

const inodoros = [
  {
    img: "/inodoro1.jpg",
    title: "",
    rows: 2,
    cols: 2,
  },
  {
    img: "/inodoro2.jpg",
    title: "",
  },
  {
    img: "/inodoro3.jpg",
    title: "",
  },

  {
    img: "/banos-adaptados-1-1-1536x768.jpg",
    title: "",
    cols: 2,
  },
];
const barras = [
  {
    img: "/barras-700x480.jpg",
    title: "",
    rows: 2,
    cols: 2,
  },
  {
    img: "/barra2.jpg",
    title: "",
    rows: 2,
    cols: 2,
  },
];
const asa = [
  {
    img: "/asa-abatible-seguridad.jpg",
    title: "",
    rows: 2,
    cols: 2,
  },
  {
    img: "/asa-abatible-768x878.jpg",
    title: "",
    rows: 2,
    cols: 2,
  },
];
const asidero = [
  {
    img: "/asidero-auxiliar-768x768.jpg",
    title: "",
    rows: 2,
    cols: 2,
  },
  {
    img: "/asidero-auxiliares.jpg",
    title: "",
    rows: 2,
    cols: 2,
  },
];
const plato = [
  {
    img: "/plato-ducha.jpg",
    title: "",
    rows: 2,
    cols: 2,
  },
  {
    img: "/plato-ducha-300x300.jpg",
    title: "",
    rows: 2,
    cols: 2,
  },
];
const Products = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "") {
      window.scrollTo(0, 0);
    }
  }, [location.hash]);

  useEffect(() => {
    const hash = location.hash;

    if (hash) {
      const element = document.getElementById(hash.substring(1));

      if (element) {
        const headerOffset = 100;
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [location.hash]);

  return (
    <>
      <Navbar />
      <div className="products-section">
        <div className="products-container">
          <br />
          <Typography className="info-title">
            <strong>Nuestros productos</strong>
            <br />
            <br id="inodoros" />
          </Typography>
          <div className="product-content">
            <div className="product-text">
              <Typography className="info-title">Inodoros</Typography>
              <br />
              <Typography className="product-subtitle">
                Con el fin de permitir el traslado desde la silla de ruedas o
                facilitar el movimiento de levantarse y sentarse, el inodoro
                debe tener una altura superior que los sanitarios comunes ( de
                45 a 50 cms), sobresalir de la pared 75 cms, para poder acercar
                la silla de ruedas y con un espacio libre mínimo de 100 cms para
                poder maniobrar con la silla de ruedas. El pulsador de descarga
                debe estar ubicado en una posición comoda La opción mas practica
                son los inodoros suspendidos que los podemos instalar a la
                altura adecuada para que las rodillas no sufran y puedan
                sentarse e incorporarse con mayor facilidad. Este tipo de
                inodoros no solo nos ayudan a mantener nuestra independencia
                durante mas tiempo, sino que también favorece la labor de los
                cuidadores Hay modelos que cuentan con una apertura frontal con
                ducha para facilitar la higiene personal (con función bidé)
              </Typography>
            </div>
            <div className="product-img-container">
              <ProductList itemData={inodoros} />
            </div>
          </div>
          <br />
          <br id="barra-de-apoyo" />
          <div className="product-content">
            <div className="product-img-container">
              <ProductList itemData={barras} />
            </div>
            <div className="product-text">
              <Typography className="info-title">
                Barras de apoyo o asideros
              </Typography>
              <br />
              <Typography className="product-subtitle">
                Colocados en cualquier lugar donde se necesite un apoyo extra
                minimizaremos con ello el riesgo de caídas y resbalones y ayuda
                a los usuarios a moverse por el baño. Se instalan normalmente en
                la ducha, bañera, junto al lavabo, pero en la zona del inodoro
                deben colocarse asideros horizontales en ambos lados, situadas a
                75 cms. Colocados en cualquier lugar donde se necesite un apoyo
                extra minimizaremos con ello el riesgo de caídas y resbalones y
                ayuda a los usuarios a moverse por el baño. Se instalan
                normalmente en la ducha, bañera, junto al lavabo, pero en la
                zona del inodoro deben colocarse asideros horizontales en ambos
                lados, situadas a 75 cms.
              </Typography>
            </div>
          </div>
          <br />
          <br />
          <div className="product-content">
            <div className="product-text">
              <Typography className="info-title">
                Asa de seguridad Abatible
              </Typography>
              <br />
              <Typography className="product-subtitle">
                También conocida como barra de seguridad para inodoro, es un
                elemento fundamental para proporcionar independencia a las
                personas con movilidad reducida o que son mayores y necesitan de
                apoyo para poder sentarse y levantarse del inodoro. Dicha barra
                va anclada directamente a la pared y dispone de un sistema de
                plegado para que no moleste mientras no se usa
              </Typography>
            </div>
            <div className="product-img-container">
              <ProductList itemData={asa} />
            </div>
          </div>
          <br />
          <br />
          <div className="product-content">
            <div className="product-img-container">
              <ProductList itemData={asidero} />
            </div>
            <div className="product-text">
              <Typography className="info-title">
                Asidero auxiliar con apoyo
              </Typography>
              <br />
              <Typography className="product-subtitle">
                Con fijaciones en el suelo y pared hace que sea una gran ayuda
                para aquellas personas que necesitan un apoyo cuando acuden al
                inodoro
              </Typography>
            </div>
          </div>
          <br />
          <br id="puerta-de-acceso" />
          <div className="product-content">
            <div className="product-text">
              <Typography className="info-title">
                Puerta de acceso al cuarto de baño
              </Typography>
              <br />
              <Typography className="product-subtitle">
                Es un componente que a veces olvidamos. Nos facilitara mucho la
                movilidad, sobre todo si la persona necesita de una silla de
                ruedas. Es recomendable que tengan de ancho entre 85 y 90
                centímetros y debemos asegurarnos de que su apertura sea siembre
                hacia afuera. De esta manera nos aseguramos de una asistencia
                segura y rápida en caso de que el usuario sufra alguna caída en
                el interior del baño y podamos abrir la puerta sin ninguna
                dificultad. Pero lo mas recomendable es la instalación de puerta
                corredera y fijar el desbloqueo de la puerta por la parte de
                afuera y no se instalen cierres o bloqueos por la parte
                interior, para facilitar el acceso en caso de necesidad
              </Typography>
              <br id="suelos-anti" /> <br />
              <Typography className="info-title">
                Suelo antideslizante
              </Typography>
              <br />
              <Typography className="product-subtitle">
                Es de vital importancia instalar un suelo antideslizante, y
                especialmente en el espacio de la ducha con el fin de evitar
                accidentes
              </Typography>
            </div>
          </div>
          <div id="plato-de-ducha"></div>
          <br />
          <br />
          <div className="product-content">
            <div className="product-text">
              <Typography className="info-title">Plato de ducha</Typography>
              <br />
              <Typography className="product-subtitle">
                El baño y todas sus áreas incluyendo la zona de la ducha debe
                tener espacio suficiente para que entre y gire la silla de
                ruedas dentro de el con facilidad, mínimo 1,50 x 1,50 metros,
                pero se recomienda ampliarlo a 1,80 x 1,80 metros para hacerlo
                mas funcional, deben de estar libre de obstáculos, suelo siempre
                antideslizante y el desagüe en perfectas condiciones: Lo ideal
                para estos casos es que se instale al mismo nivel que el suelo
                del baño para evitar desnivele. Ducha sin obstáculos en el suelo
                y con sujeciones en su interior, garantizando una entrada de
                forma accesible evitando los tropiezos y asegurando su entrada
                de forma segura
              </Typography>
            </div>
            <div className="product-img-container">
              <ProductList itemData={plato} />
            </div>
          </div>
          <br />
          <br />
          <div className="product-content">
            <div className="product-text">
              <Typography className="info-title" id="mampara-de-ducha">
                Mampara de ducha
              </Typography>
              <br />
              <Typography className="product-subtitle">
                Sin perfileria inferior para prevenir tropiezos. Según las
                necesidades del usuario y la disponibilidad de espacio en el
                baño, se pueden instalar mamparas con varias hojas a diferentes
                alturas, plegables o con sistema de apertura abatible (hacia
                adentro y hacia afuera) lo que permite la total apertura de las
                puertas para acceder al plato de ducha, lo que lógicamente
                reduce las complicaciones para personas con movilidad reducica y
                contar con un mejor acceso.
              </Typography>
              <br id="lavado-suspendido" /> <br />
              <Typography className="info-title">
                Lavado suspendido y sin obstaculos
              </Typography>
              <br />
              <Typography className="product-subtitle">
                Sin pedestal, ni muebles, ni cajones para que el espacio
                inferior este libre y facilitar su uso en caso de necesitar una
                silla de ruedas. Se recomienda que el borde delantero sea
                cóncavo para facilitar de esta forma el acceso a los grifos y
                pueden contar con mecanismos manuales o mecánicos para su
                inclinación.
              </Typography>
              <br /> <br id="griferia" />
              <Typography className="info-title">Griferia</Typography>
              <br />
              <Typography className="product-subtitle">
                Los grifos más accesibles son los monomandos con maneta
                gerontológica, ya que resultan fáciles de accionar por cualquier
                usuario. Esta maneta se caracteriza por ser más larga de lo
                normal y ergonómica, lo que permite un acceso más fácil o
                incluso manejar el grifo con el antebrazo si fuera necesario.
                Hay que tener en cuenta uno de los peligros del baño para las
                personas mayores es el dejar el grifo abierto y provocar una
                inundación por descuido. Una solución para ello son los grifos
                electrónicos con sensores de encendido y apagado automático.
                Otro punto a tener en cuenta, es la regulación de la temperatura
                del agua de la ducha, también puede convertirse en un problema.
                En este caso se recomienda optar por griferías termostáticas
                para la ducha, lo que permite elegir la temperatura del agua
                antes de que salga el flujo. Este tipo de grifería cuenta además
                con un dispositivo de seguridad anti quemaduras, que evitara que
                el agua salga demasiado caliente.
              </Typography>
              <br id="espejo" /> <br />
              <Typography className="info-title">Espejo</Typography>
              <br />
              <Typography className="product-subtitle">
                Si el espejo es fijo este no debe situarse a mas de 100 cms de
                altura desde el suelo. Para que una persona en silla de ruedas
                pueda verse si dificultad además debe de colocarse con un ángulo
                de inclinación de 10 grados con respecto a la vertical.
              </Typography>
              <br id="llamador-emergencia" /> <br />
              <Typography className="info-title">
                Llamador de emergencia
              </Typography>
              <br />
              <Typography className="product-subtitle">
                Los baños deben de contar con una alarma visual y sonora, para
                evidenciar una caída o emergencia en el baño. La persona dentro
                del baño baño, puede activar la estación instalada en la pared.
                Una vez activado el cordon de llamada, la señal llegara a una
                lampara instalada en el dintel de la puerta, la cual emitirá una
                señal audible, para ser percibida por las personas a cargo y
                puedan brindar ayudaHay que tener en cuenta uno de los peligros
                del baño para las personas mayores es el dejar el grifo abierto
                y provocar una inundación por descuido. Una solución para ello
                son los grifos electrónicos con sensores de encendido y apagado
                automático.
              </Typography>
              <br />
              <Typography className="product-subtitle">
                Otro punto a tener en cuenta, es la regulación de la temperatura
                del agua de la ducha, también puede convertirse en un problema.
                En este caso se recomienda optar por griferías termostáticas
                para la ducha, lo que permite elegir la temperatura del agua
                antes de que salga el flujo. Este tipo de grifería cuenta además
                con un dispositivo de seguridad anti quemaduras, que evitara que
                el agua salga demasiado caliente.
              </Typography>
              <br /> <br />
              <Typography className="info-title" id="asientos">
                Asientos
              </Typography>
              <br />
              <Typography className="product-subtitle">
                Es conveniente su colocacion en el plato de ducha ya sea fijo o
                abatibe, instalado en la pared de la ducha, y que facilitara el
                aseo.
              </Typography>
            </div>
          </div>
        </div>
        <WhatsAppButton />
        <div className="footer-section">
          <Footer />
        </div>
      </div>
    </>
  );
};
export default Products;
